const colorData = {
    "ferrari-red": {
      title: "Ferrari Red",
      subtitle: "Ferrari's red option list",
      paragraphText:
        "Ferrari will always be synonymous with red. The different shades look stunning in the light, and will bring road presence to any model.",
      imageSrc: "../img/porsche-header.png",
      color: "",
      longerDescription:"Red Ferrari's are the most classic pairing in the supercar. These colors are classics because they outline Ferrari's elegance beautifully, and hold their value well. While Rosso Corsa is the original Ferrari Red, there are multiple shades to choose from from lighter reds like Rosso Dino all the way to darker colors like Rosso Fiorano.Ferrari red has been common as far back as the original GTOs, but is still very common on cars like the LaFerrari, Pista, and 812 Superfast. It has been so famous that its iconic color has made its way into iconic shoe brands like Pumas or Jordans.",
      colors: [
        {
            color_name: "Rosso Scuderia",
        short_description: "Ferrari's bright red color.",
         slug: "/car-color/rosso-scuderia/",
          hex_text: "#ff2800",
          rgb_text: "rgb(255,40,0)",
          cmyk_text: "0, 84, 100, 0",
          ral_text: "RAL 3024",
          mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/6c06d259c57011e03a7497b44f0607d39cd492fa-750x500.png"}},
        },
        {
          color_name: "Rosso Corsa",
         short_description: "Ferrari's classic red color.",
         slug: "/car-color/rosso-corsa/",
          hex_text: "#D40000",
          rgb_text: "rgb(212,0,0)",
          cmyk_text: "0, 100, 100, 17",
          ral_text: "RAL 3028",
          mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/2d97dc42e78699af41ddcecf74369af5d2d38351-750x500.png" }},
        },
        {
          color_name: "Rosso Mugello",
          short_description: "Ferrari's dark red color.",
          slug: "/car-color/rosso-mugello/",
          hex_text: "#7A212A",
          rgb_text: "rgb(122,33,42)",
          cmyk_text: "0, 73, 66, 52",
          ral_text: "RAL 3032",
          mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/912cb7adb4d4afad6d507c6102a71258488659a1-750x500.png"} },
        },
        {
            color_name: "Rosso Fuoco",
            short_description: "Ferrari's triple layer red color.",
            slug: "/car-color/rosso-fuoco/",
            hex_text: "#D13442",
            rgb_text: "rgb(209,52,66)",
            cmyk_text: "0, 75, 68, 18",
            ral_text: "RAL 3018",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/762a3f88e5511e38a9db588c213d5eb93e6c2100-750x500.png"} },
          },
          {
            color_name: "Rosso Dino",
            short_description: "Ferrari's red/orange color.",
            slug: "/car-color/rosso-dino/",
            hex_text: "#FC652E",
            rgb_text: "rgb(252,101,46)",
            cmyk_text: "0, 60, 82, 1",
            ral_text: "RAL 2008",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/36ce865395e12e555696c8d8154a59fa7be072d5-750x500.png"} },
          },
          {
            color_name: "Rosso Fiorano",
            short_description: "Ferrari's ruby red color.",
            slug: "/car-color/rosso-fiorano/",
            hex_text: "#5D0001",
            rgb_text: "rgb(93,0,1)",
            cmyk_text: "0, 100, 99, 64",
            ral_text: "RAL 3004",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/1c5f09b79ec3ef2caddc1caaa39832422ef3b230-750x500.png"} },
          },
          {
            color_name: "Rosso 70 Anni",
            short_description: "Ferrari's 70th anniversary celebration color.",
            slug: "/car-color/rosso-70-anni/",
            hex_text: "#C40C19",
            rgb_text: "rgb(196,12,25)",
            cmyk_text: "0, 94, 87, 23",
            ral_text: "RAL 3020",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/02be289d0033bfc61d6c4d1f2c3c2dcf4011f8df-750x500.png"} },
          },
      ],

    },
    "ferrari-blue": {
        title: "Ferrari Blue",
        subtitle: "Ferrari's blue option list",
        paragraphText:
          "Ferrari's haven't always been associated with blue, but they are all stunning option choices on any model. The color accentuates the elegance of the brand.",
        imageSrc: "../img/porsche-header.png",
        color: "",
        longerDescription:"Ferrari's option list offers a variety of blue hues, ranging from baby blue to darker blue colors. While the brand has historically been synonymous with red, blue is an excellent choice for the brand's identity. Cars as early as the Daytona were seen in blue, and recent cars like the 812 Superfast have made blue far more common amongst Ferrari enthusiasts. The color looks stealthy, but reflects well in the sunlight. Some of our favorite colors include Blu Swaters, and Blu Abu Dhabi. These colors are both elegant , and exciting, which is a perfect representation of the Ferrari brand.",
        colors: [
          {
              color_name: "Blu Scozia",
          short_description: "Ferrari's dark blue color.",
           slug: "/car-color/blu-scozia/",
            hex_text: "#505C77",
            rgb_text: "rgb(80,92,119)",
            cmyk_text: "33, 23, 0, 53",
            ral_text: "RAL 5000",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/9c209490619769e277c6595d0198c0e6042c19f9-750x500.png"}},
          },
          {
            color_name: "Blu Swaters",
           short_description: "Ferrari's royal blue color.",
           slug: "/car-color/blu-swaters/",
            hex_text: "#163166",
            rgb_text: "rgb(22,49,102)",
            cmyk_text: "78, 52, 0, 60",
            ral_text: "RAL 5022",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/d5deaf4690452fa08bb7ef6a978ed036de1b1aca-750x500.png" }},
          },
          {
            color_name: "Blu Pozzi",
            short_description: "Ferrari's deep blue color.",
            slug: "/car-color/blu-pozzi/",
            hex_text: "#2C3970",
            rgb_text: "rgb(44,57,112)",
            cmyk_text: "61, 49, 0, 56",
            ral_text: "RAL 5022",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/b12b25aa03033a9371aca2b4bb9d84946f85da43-750x500.png"} },
          },
          {
              color_name: "Blu Abu Dhabi",
              short_description: "Ferrari's light blue color.",
              slug: "/car-color/blu-abu-dhabi/",
              hex_text: "#2885B5",
              rgb_text: "rgb(40,133,181)",
              cmyk_text: "78, 27, 0, 29",
              ral_text: "RAL 5012",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/1e289716427b188c25b5f3f6713c721ac351353c-750x500.png"} },
            },
            {
              color_name: "Blu Tour De France",
              short_description: "Ferrari's classic blue color.",
              slug: "/car-color/blu-tour-de-france/",
              hex_text: "#2243AA",
              rgb_text: "rgb(34,67,170)",
              cmyk_text: "80, 61, 0, 33",
              ral_text: "RAL 5002",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/75c4e7d2d6b7fb53ae87846b848329f016f19c57-750x500.png"} },
            },
        ],
  
      },
      "lamborghini-black": {
        title: "Lamborghini Black",
        subtitle: "Lamborghini's black option list",
        paragraphText:
          "Black is the perfect color choice for a stealth Lamborghini. The company provides an array of option colors from lighter blacks all the way to dark matte blacks.",
        imageSrc: "../img/porsche-header.png",
        color: "",
        longerDescription:"The Lamborghini Aventador is a perfect choice for a black color. It makes the car look stealthy, and does a good job of highlighting the specific angled panels that make the car. Black Lamborghinis vary in price; some come as standard free options, and others can become extremely expensive through Lamborghini's Ad Personam program. The Urus is also a great option for the black color, because it is commonly used as a daily driver. This is Lamborghini's first real venture into daily driving, and is a safe choice for any Urus.",
        colors: [
          {
              color_name: "Nero Aldebaran",
          short_description: "Lamborghini's shiny black color.",
           slug: "/car-color/nero-aldebaran/",
            hex_text: "#0D1015",
            rgb_text: "rgb(13,16,21)",
            cmyk_text: "38, 24, 0, 92",
            ral_text: "RAL 9005",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/be1d0c21947846fe83449d2e1010034168dfdf65-750x500.png"}},
          },
          {
            color_name: "Nero Granatus",
           short_description: "Lamborghini's contemporary black color.",
           slug: "/car-color/nero-granatus/",
            hex_text: "#92555D",
            rgb_text: "rgb(146,85,93)",
            cmyk_text: "0, 42, 36, 43",
            ral_text: "RAL 4002",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/c55d540654cb5d52192f7ae90794d8a398773b8a-750x500.png" }},
          },
          {
            color_name: "Nero Helene",
            short_description: "Lamborghini's Ad Personam black color.",
            slug: "/car-color/nero-helene/",
            hex_text: "#151618",
            rgb_text: "rgb(21,22,24)",
            cmyk_text: "13, 8, 0, 91",
            ral_text: "RAL 9005",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/6cd37b232dbb7472c3af0b4eebd0aa05d08d64ea-750x500.png"} },
          },
          {
              color_name: "Nero Noctis",
              short_description: "Lamborghini's Ad Personam metallic black color.",
              slug: "/car-color/nero-noctis/",
              hex_text: "#292927",
              rgb_text: "rgb(41,41,39)",
              cmyk_text: "0, 0, 5, 84",
              ral_text: "RAL 9017",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/336fc4de472b2ef7cbe638765daa71465fddac8e-750x500.png"} },
            },
            {
              color_name: "Nero Pegaso",
              short_description: "Lamborghini's Ad Personam dark black color.",
              slug: "/car-color/nero-pegaso/",
              hex_text: "#080D10",
              rgb_text: "rgb(8,13,16)",
              cmyk_text: "50, 19, 0, 94",
              ral_text: "RAL 9005",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/26d8df945dfda40a52da52abe033e67b6be93f59-750x500.png"} },
            },
            {
                color_name: "Nero Nemesis",
                short_description: "Lamborghini's Ad Personam matte black color.",
                slug: "/car-color/nero-nemesis/",
                hex_text: "#312F30",
                rgb_text: "rgb(49,47,48)",
                cmyk_text: "0, 4, 2, 81",
                ral_text: "RAL 9004",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/7c991670eb34e0f89bba0e27e24e2cd666b1a1c1-750x500.png"} },
              },
        ],
  
      },

      "lamborghini-grey": {
        title: "Lamborghini Grey",
        subtitle: "Lamborghini's grey option list",
        paragraphText:
          "Grey Lamborghini's have been more popular recently. They provide a perfect base layer for accent colors, while keeping Lamborghini's iconic strong road presence.",
        imageSrc: "../img/porsche-header.png",
        color: "",
        longerDescription:"The most color options that Lamborghini offers are for Grigio paint color. There are a variety of light to dark grey shades offered in both standard and Ad Personam options. Grigio is a perfect blend between stealth and pop. It's not too bright, and looks menacing on the road, but still shines in the sunlight and stands out among other cars on the road. The Aventador, Huracan and Urus all offer Grey paint options, but it hasn't been a common color choice until the most recent generation of Lamborghinis.",
        colors: [
          {
              color_name: "Grigio Telesto",
          short_description: "Lamborghini's sportiva grey color.",
           slug: "/car-color/grigio-telesto/",
            hex_text: "#7692A5",
            rgb_text: "rgb(118,146,165)",
            cmyk_text: "28, 12, 0, 35",
            ral_text: "RAL 5024",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/0b6ea0fe121ead27f981e5eab6c11dff698d3c06-750x500.png"}},
          },
          {
            color_name: "Grigio Antares",
           short_description: "Lamborghini's Ad Personam metallic grey color.",
           slug: "/car-color/grigio-antares/",
            hex_text: "#A6ADB7",
            rgb_text: "rgb(166,173,183)",
            cmyk_text: "9, 5, 0, 28",
            ral_text: "RAL 7040",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/91eaa815b7a3026da94b12a708a0aed149419763-750x500.png" }},
          },
          {
            color_name: "Grigio Ater",
            short_description: "Lamborghini's Ad Personam dark grey color.",
            slug: "/car-color/grigio-ater/",
            hex_text: "#727274",
            rgb_text: "rgb(114,114,116)",
            cmyk_text: "2, 2, 0, 55",
            ral_text: "RAL 9023",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/a2be38fa4d02b29dbcfe4a23108cc85f6f77e5eb-750x500.png"} },
          },
          {
              color_name: "Grigio Estoque",
              short_description: "Lamborghini's Ad Personam metallic grey color.",
              slug: "/car-color/grigio-estoque/",
              hex_text: "#ACACAE",
              rgb_text: "rgb(172,172,174)",
              cmyk_text: "1, 1, 0, 32",
              ral_text: "RAL 9006",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/ccfa334f1fa3c471355d7329cdc88738130861bb-750x500.png"} },
            },
            {
              color_name: "Grigio Hati",
              short_description: "Lamborghini's Ad Personam light grey color.",
              slug: "/car-color/grigio-hati/",
              hex_text: "#C7D7E7",
              rgb_text: "rgb(199,215,231)",
              cmyk_text: "14, 7, 0, 9",
              ral_text: "RAL 7047",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/799a1e7c33dc93bc7cd2014ddeacaf3672156a77-750x500.png"} },
            },
            {
                color_name: "Grigio Keres",
                short_description: "Lamborghini's Ad Personam metallic grey color.",
                slug: "/car-color/grigio-keres/",
                hex_text: "#6F6F6F",
                rgb_text: "rgb(111,111,111)",
                cmyk_text: "0, 0, 0, 56",
                ral_text: "RAL 7005",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/257e82cc8ac1d96cdacd1a1fc340c29f762034b1-750x500.png"} },
              },
            {
                color_name: "Grigio Liqueo",
                short_description: "Lamborghini's Ad Personam metallic grey color.",
                slug: "/car-color/grigio-liqueo/",
                hex_text: "#6F6F6F",
                rgb_text: "rgb(111,111,111)",
                cmyk_text: "0, 0, 0, 56",
                ral_text: "RAL 7005",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/478cefda9b0a2527ce1a2882661554dfcfc23d60-750x500.png"} },
              },
              {
                color_name: "Grigio Lynx",
                short_description: "Lamborghini's Ad Personam grey color.",
                slug: "/car-color/grigio-lynx/",
                hex_text: "#707176",
                rgb_text: "rgb(112,113,118)",
                cmyk_text: "5, 4, 0, 54",
                ral_text: "RAL 9023",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/6737cf7ec8a938fe00f5ccd26ac5f2b8943a5d5a-750x500.png"} },
              },
              {
                color_name: "Grigio Nimbus",
                short_description: "Lamborghini's Ad Personam bright light grey color.",
                slug: "/car-color/grigio-nimbus/",
                hex_text: "#6B7278",
                rgb_text: "rgb(107,114,120)",
                cmyk_text: "11, 5, 0, 53",
                ral_text: "RAL 7031",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/940dfb8cb982f79e07cdb49829fe22cf34b43d06-750x500.png"} },
              },
        ],
  
      },

      "lamborghini-blue": {
        title: "Lamborghini Blue",
        subtitle: "Lamborghini's blue option list.",
        paragraphText:
          "Blue Lamborghini's are becoming more popular with time. The variety of options, from light blue to dark blue make this color an attractive choice for buyers that want a splash of color without going over the top.          ",
        imageSrc: "../img/porsche-header.png",
        color: "",
        longerDescription:"Lamborghini Aventadors brought out a variety of new blue colors to the brand, some of which will become powerful legacy colors. Blue Lamborghini paint choices vary in price. They cost different amounts based on the depth of color, and complexity of paint job. Lamborghini's Ad Personam arm offers a lot of customization to potential owners. There are many different shades of blue that can be done, all at different prices.        ",
        colors: [
          {
              color_name: "Blu Cepheus",
          short_description: "Lamborghini's contemporary blue color.",
           slug: "/car-color/blu-cepheus/",
            hex_text: "#2CAEFE",
            rgb_text: "rgb(57,191,254)",
            cmyk_text: "78, 25, 0, 0",
            ral_text: "RAL 5024",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/28ff3487f5c587aade9d0c34d67664d65abd3291-750x500.png"}},
          },
          {
            color_name: "Blu Aegir",
           short_description: "Lamborghini's Ad Personam metallic grey color.",
           slug: "/car-color/blu-aegir/",
            hex_text: "#A6ADB7",
            rgb_text: "rgb(44,174,254)",
            cmyk_text: "83, 31, 0, 0",
            ral_text: "RAL 5024",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/bc436014b244f61e3833b54ca6ba67bda0c47e01-750x500.png" }},
          },
          {
            color_name: "Blu Glauco",
            short_description: "Lamborghini's Ad Personam teal color.",
            slug: "/car-color/blu-glauco/",
            hex_text: "#08C7E3",
            rgb_text: "rgb(8,199,227)",
            cmyk_text: "96, 12, 0, 11",
            ral_text: "RAL 6027",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/7fb066dfc3367d4685507ae21d6c9533a7f659b8-750x500.png"} },
          },
          {
              color_name: "Blu Eleos",
              short_description: "Lamborghini's Ad Personam shiny blue color.",
              slug: "/car-color/blu-eleos/",
              hex_text: "#418DD8",
              rgb_text: "rgb(65,141,216)",
              cmyk_text: "70, 35, 0, 15",
              ral_text: "RAL 5024",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/64211fc40857717246a2dc3b26f290063c454e01-750x500.png"} },
            },
            {
              color_name: "Blu Nila",
              short_description: "Lamborghini's shiny blue color.",
              slug: "/car-color/blu-nila/",
              hex_text: "#017EF4",
              rgb_text: "rgb(1,126,244)",
              cmyk_text: "100, 48, 0, 4",
              ral_text: "RAL 5014",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/c618e88ae993c6905b7d2798402f7d295db81155-750x500.png"} },
            },
            {
                color_name: "Blu Uranus",
                short_description: "Lamborghini's pearl ocean color.",
                slug: "/car-color/blu-uranus/",
                hex_text: "#0177A4",
                rgb_text: "rgb(1,119,164)",
                cmyk_text: "99, 27, 0, 36",
                ral_text: "RAL 5015",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/693941bb6126f6634bce1d6ebc5a25cf763cfba9-750x500.png"} },
              },
            {
                color_name: "Blu Astraeus",
                short_description: "Lamborghini's Ad Personam deep blue color.",
                slug: "/car-color/blu-astraeus/",
                hex_text: "#00024C",
                rgb_text: "rgb(0,2,76)",
                cmyk_text: "100, 97, 0, 70",
                ral_text: "RAL 5022",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/30ac346fdf9ab4ba0bb05247bc9a5e3a3953612c-750x500.png"} },
              },
              {
                color_name: "Blu Notte",
                short_description: "Lamborghini's classic blue color.",
                slug: "/car-color/blu-notte/",
                hex_text: "#212E58",
                rgb_text: "rgb(33,46,88)",
                cmyk_text: "63, 48, 0, 65",
                ral_text: "RAL 5022",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/7a45a79f43f5ac681d3ce1d7b2020577ab229ac2-750x500.png"} },
              },
              {
                color_name: "Blu Fontus",
                short_description: "Lamborghini's Ad Personam dark blue color.",
                slug: "/car-color/blu-fontus/",
                hex_text: "#313247",
                rgb_text: "rgb(49,50,71)",
                cmyk_text: "31, 30, 0, 72",
                ral_text: "RAL 5013",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/6da02add975ba0b6f157288ee024c97d80f440c2-750x500.png"} },
              },
              {
                color_name: "Blu Nethuns",
                short_description: "Lamborghini's sportiva blue color.",
                slug: "/car-color/blu-nethuns/",
                hex_text: "#1336EA",
                rgb_text: "rgb(19,54,234)",
                cmyk_text: "92, 77, 0, 8",
                ral_text: "RAL 5000",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/984b3bad886032a52f467a5f1fa5300af9f86f09-750x500.png"} },
              },
              {
                color_name: "Blu Nereid",
                short_description: "Lamborghini's Ad Personam metallic blue color.",
                slug: "/car-color/blu-nereid/",
                hex_text: "#2539BC",
                rgb_text: "rgb(37,57,188)",
                cmyk_text: "80, 70, 0, 26",
                ral_text: "RAL 5002",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/5c14a6b9d55fc4b1cd0b7e5807d65de08f1a7111-750x500.png"} },
              },
              {
                color_name: "Blu Le Mans",
                short_description: "Lamborghini's Ad Personam bright blue color.",
                slug: "/car-color/blu-le-mans/",
                hex_text: "#0690FF",
                rgb_text: "rgb(6,144,255)",
                cmyk_text: "98, 44, 0, 0",
                ral_text: "RAL 5024",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/3f436b5dfeb9a56ca7e951f3dc839c153ea60a5f-750x500.png"} },
              },
              {
                color_name: "Blu Caelum",
                short_description: "Lamborghini's Ad Personam royal blue color.",
                slug: "/car-color/blu-caelum/",
                hex_text: "#054AE3",
                rgb_text: "rgb(5,74,227)",
                cmyk_text: "98, 67, 0, 11",
                ral_text: "RAL 5023",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/e9b526ff2aa6c984cdcea6f622eceb6f076d64f5-750x500.png"} },
              },
        ],
      },

      "lamborghini-green": {
        title: "Lamborghini Green",
        subtitle: "Lamborghini's green option list.",
        paragraphText:
          "Green represents Lamborghini's ethos perfectly; bright colors that grab everyone's attention. With every model, Lamborghini's evolves their green colors to better represent the new cars and their angles, as shown in the new Sian.",
        imageSrc: "../img/porsche-header.png",
        color: "",
        longerDescription:"Green Lamborghini's look stunning on Huracan models. The option list provides a lot of optionality: you can choose from lime green choices all the way to darker greens. Lamborghini Aventador's that are optioned in green have incredible road presence. They don't have to be an expensive option choice, and are sure to make your car pop on the road. The Lamborghini Sian debuted a new green option color: Verde Gea. This color choices is a beautiful matte green that pairs well with bronze accents. This is one of the first Lamborghini's that have been debuted in a green color, and is a sign from the brand of what's to come.",
        colors: [
          {
              color_name: "Verde Mantis",
          short_description: "Lamborghini's shiny green color.",
           slug: "/car-color/verde-mantis/",
            hex_text: "#7DC23B",
            rgb_text: "rgb(125,194,59)",
            cmyk_text: "36, 0, 70, 24",
            ral_text: "RAL 6018",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/6c7ba3bc23c12c69cd04de8e7332dba81e495e9e-750x500.png"}},
          },
          {
            color_name: "Verde Selvans",
           short_description: "Lamborghini's sportiva green color.",
           slug: "/car-color/verde-selvans/",
            hex_text: "#67C52F",
            rgb_text: "rgb(103,197,47)",
            cmyk_text: "48, 0, 76, 23",
            ral_text: "RAL 6038",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/62bfe2c5d5cd72913fdbdde1ef8f448ddf4a8144-750x500.png" }},
          },
          {
            color_name: "Verde Gea Lucido",
            short_description: "Lamborghini's contemporary green color.",
            slug: "/car-color/verde-gea-lucido/",
            hex_text: "#8B8970",
            rgb_text: "rgb(139,137,112)",
            cmyk_text: "0, 1, 19, 45",
            ral_text: "RAL 7034",
            mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/2b70143524a57908eac2a13e2c3d265141412308-750x500.png"} },
          },
          {
              color_name: "Verde Citrea",
              short_description: "Lamborghini's pearl green color.",
              slug: "/car-color/verde-citrea/",
              hex_text: "#9AF95D",
              rgb_text: "rgb(154,249,93)",
              cmyk_text: "38, 0, 63, 2",
              ral_text: "RAL 6019",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/93db46f4759c89623b62e2c1c3f024db2468ac1b-750x500.png"} },
            },
            {
              color_name: "Verde Metallic",
              short_description: "Lamborghini's classic british racing green color.",
              slug: "/car-color/verde-metallic/",
              hex_text: "#8FC028",
              rgb_text: "rgb(143,192,40)",
              cmyk_text: "26, 0, 79, 25",
              ral_text: "RAL 6018",
              mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/af3c4adf5ceb0e6b3fc5b42a2d8c28b39e7240ee-750x500.png"} },
            },
            {
                color_name: "Verde Scandal",
                short_description: "Lamborghini's classic green color.",
                slug: "/car-color/verde-scandal/",
                hex_text: "#84E12E",
                rgb_text: "rgb(132,225,46)",
                cmyk_text: "41, 0, 80, 12",
                ral_text: "RAL 6038",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/6319aa32ae44fc92c500fbd6b57f96bcc47cd117-750x500.png"} },
              },
            {
                color_name: "Verde Ermes",
                short_description: "Lamborghini's Ad Personam dark green color.",
                slug: "/car-color/verde-ermes/",
                hex_text: "#546E51",
                rgb_text: "rgb(84,110,81)",
                cmyk_text: "24, 0, 26, 57",
                ral_text: "RAL 6010",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/352e2ebdc17eda18d799e72e760e2183d8100658-750x500.png"} },
              },
              {
                color_name: "Verde Ithaca",
                short_description: "Lamborghini's Ad Personam bright green color.",
                slug: "/car-color/verde-ithaca/",
                hex_text: "#AEFF7E",
                rgb_text: "rgb(174,255,126)",
                cmyk_text: "32, 0, 51, 0",
                ral_text: "RAL 6019",
                mainImage: {asset: {url:"https://cdn.sanity.io/images/zlmf9sgs/production/8de996dda5ed48b07e9dcb983967635b29ac8728-750x500.png"} },
              },  
        ],
      },
  };
  
  export default colorData;
  