import image1 from "../img/ferrari-cover-photo-1.webp";
import image2 from '../img/mclaren-cover-photo-1.webp';
import image3 from "../img/porsche-cover-photo-2.webp";

export const SliderData = [
  {
    src: image1,
  },
  {
    src: image2,
  },
  {
    src: image3,
  },
];

export default SliderData;
